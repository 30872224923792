import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import {ForgetPasswordComponent } from './login/forget-password/forget-password.component';
import { LoginComponentResolver } from  './login/login.component.resolver';

const routes: Routes =[
  {path:'', component:LoginComponent,resolve: { info: LoginComponentResolver }},
  {path:'login', component:LoginComponent,resolve: { info: LoginComponentResolver }},
 

  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
   {
    path: '',
    component: AdminLayoutComponent,
   
    children: [
        {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  }]},
  
];

@NgModule({
  imports:  [RouterModule.forRoot(routes,{})],
 exports: [],
 providers: [LoginComponentResolver]
})
export class AppRoutingModule { }

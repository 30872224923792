import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { AppComponent } from './app.component';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { PrialageManagementComponent } from './prialage-management/prialage-management.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ForgetPasswordComponent } from './login/forget-password/forget-password.component';
import { VerfiyOtpComponent } from './login/verfiy-otp/verfiy-otp.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { EditPrivComponent } from './prialage-management/edit-priv/edit-priv.component';
import { CreatePrivComponent } from './prialage-management/create-priv/create-priv.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { AuthInterceptor } from './services/interceptor';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EditRoleComponent } from './layouts/admin-layout/role-management/edit-role/edit-role.component';
import { DeletePrivComponent } from './prialage-management/delete-priv/delete-priv.component';

@NgModule({
  imports: [
    MatFormFieldModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NavbarModule,
    SidebarModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatSlideToggleModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatSelectModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
      enableHtml: true,
      closeButton: true,
      progressBar: true,
    }),
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    PrialageManagementComponent,
    ForgetPasswordComponent,
    VerfiyOtpComponent,
    ResetPasswordComponent,
    EditRoleComponent,
    ChangePasswordComponent,
    EditPrivComponent,
    CreatePrivComponent,
    DialogBoxComponent,
    DeletePrivComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

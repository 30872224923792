import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ToastrService } from 'ngx-toastr';
import { elementAt } from 'rxjs-compat/operator/elementAt';
import { AuthService } from 'app/services/auth/auth.service';
import { NotifierService } from 'app/services/helper/notifier.service';
import { SessionService } from 'app/services/helper/session.service';
import { LoginModal } from 'app/models/auth-modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted = false;
  loginForm: FormGroup;
  hide = true;
  login: LoginModal;


  constructor(
    private fb: FormBuilder,
     private router: Router,
     private auth: AuthService,
     public dialog: MatDialog,
     private _notifier: NotifierService,
     private _SessionService :SessionService,
     private _ActivatedRoute:ActivatedRoute,
     ) {
      this.login = new LoginModal();
     this.loginForm = fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      password: ['', Validators.required],
    });

  }

  ngOnInit(): void {
    this._ActivatedRoute.data.subscribe(data => {
      const currentIpInfo = data.info;
      this.login.browser = this.auth.detectBrowser();
      this.login.country = currentIpInfo.country;
      this.login.loginLocation = currentIpInfo.region;
      this.login.loginIp = currentIpInfo.ip;
      })
  }

  openDialog() {
    this.dialog.open(ForgetPasswordComponent);
  }

  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) { control.markAsTouched(); this.markFormTouched(control); }
      else { control.markAsTouched(); };
    });
  };

  submitForm() {
    if (this.loginForm.valid) {
      let address = '';
      let device = this.login.device;
      let loginIp =  this.login.loginIp;
      var body = {
        "address": address,
        "browser": this.login.browser,
        "device": device,
        "email": this.loginForm.controls.email.value,
        "fcmToken": '',
        "loginIp": loginIp,
        "password": this.loginForm.controls.password.value
      }

     this.auth.login(body).then((data: any) => {
       if(data && data.isSuccess){
        this.router.navigate(['/dashboard']);
        this._SessionService.setSessions({ token: data.data.accessToken });
        this._SessionService.setSessions({ email: data.data.email });
        this._SessionService.setSessions({ userId: data.data.userId });
        this._SessionService.setSessions({ refreshToken : data.data.refreshToken });
        this._SessionService.setSessions({ userRole : data.data.roles[0] });
        this._SessionService.setSessions({ timeZone : data.data.timeZone });
        this._SessionService.setSessions({ userName : data.data.fullName });
        this._notifier.showSuccess(data.message);
         }else{
         this._notifier.showError(data.message);
       }
     })
    }
  }








}

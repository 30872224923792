import { Component, OnInit } from '@angular/core';
import { SessionService } from 'app/services/helper/session.service';

declare const $: any;

  declare interface RouteInfo {
    path: string;
    title: string;
    subTitles: any;
    icon: string;
    class: string;
  }
  declare interface RouteInfos {
    path: string;
    title: string;
    icon: string;
    class: string;
  }



export const ROUTES: RouteInfo[] = [
    // { path: '/dashboard', title: 'Role Management',  icon: 'pe-7s-graph', class: '',subTitles: [{ path: '/courses', icon: 'person', subMenu: 'Course' }, { path: '/subjects', icon: 'person', subMenu: 'Subject' }, { path: '/chapter', subMenu: 'Chapters' }, { path: '/topic', subMenu: 'Topics' }, { path: '/lecture', subMenu: 'Lectures' }], icon: '', class: '' }, },
    // { path: '/role-management', title: 'Role Management', icon: '', class: '' },
    { path: '/', title: 'role Management', subTitles: [{ path: '', icon: '', subMenu: 'Admin User' }, { path: '/subjects', icon: '', subMenu: 'App User' }], icon: '', class: '' },
    { path: '/', title: 'User Management', subTitles: [{ path: '/users', icon: '', subMenu: 'Admin User' }, { path: '/subjects', icon: '', subMenu: 'App User' }], icon: '', class: '' },
    { path: '/', title: 'RTS Management', subTitles: [{ path: 'dashboard', icon: '', subMenu: '' }, { path: '/', icon: '', subMenu: '' }], icon: '', class: '' },
    { path: '/', title: 'Fee Management', subTitles: [{ path: 'dashboard', icon: '', subMenu: '' }, { path: '', icon: '', subMenu: '' }], icon: '', class: '' },
    { path: '/', title: 'Plan Management', subTitles: [{ path: 'dashboard', icon: '', subMenu: '' }, { path: '', icon: '', subMenu: '' }], icon: '', class: '' },
    { path: '/', title: 'Analyst Management', subTitles: [{ path: 'dashboard', icon: '', subMenu: '' }, { path: '', icon: '', subMenu: '' }], icon: '', class: '' },
    { path: '/', title: 'Job Management', subTitles: [{ path: 'dashboard', icon: '', subMenu: '' }, { path: '', icon: '', subMenu: '' }], icon: '', class: '' },
    // { path: '/user', title: 'User Management',  icon:'pe-7s-user', class: '' },
    // { path: '/table', title: 'RTS Management',  icon:'pe-7s-note2', class: '' },
    // { path: '/typography', title: 'Fee Management',  icon:'pe-7s-news-paper', class: '' },
    // { path: '/icons', title: 'Plan Managemenr',  icon:'pe-7s-science', class: '' },
    // { path: '/maps', title: 'Analystis Management',  icon:'pe-7s-map-marker', class: '' },
    // { path: '/notifications', title: 'Job Management',  icon:'pe-7s-bell', class: '' },
    // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'pe-7s-rocket', class: 'active-pro' },
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']

})
export class SidebarComponent implements OnInit {

  menuItems: any[] = [];
  userRole: any;
  constructor( private sessionService: SessionService ) { }

  ngOnInit() {
    this.userRole = this.sessionService.getSession('userRole')
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}

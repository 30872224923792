import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-edit-priv',
  templateUrl: './edit-priv.component.html',
  styleUrls: ['./edit-priv.component.css']
})
export class EditPrivComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  rols = new FormControl();
  rolsList: string[] = ['User Management Read', 'User Management Delete'];

}

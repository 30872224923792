import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'app/services/main.service';

@Component({
  selector: 'app-delete-priv',
  templateUrl: './delete-priv.component.html',
  styleUrls: ['./delete-priv.component.css'],
})
export class DeletePrivComponent implements OnInit {
  id: any;
  createPrivilegeForm: FormGroup;
  singleRole: any;

  constructor(
    private mainService: MainService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<DeletePrivComponent>
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.id = this.singleRole.id;
    this.createPrivilegeForm = this.fb.group({
      name: new FormControl(this.singleRole.name),
    });
  }

  deletePrivilage() {
    this.mainService.deletePrivilage(this.id).then((data) => {
      if (data && data.isSuccess) {
        this.toastr.success(data.message);
        this.dialogRef.close({ event: 'close' });
      } else {
        this.toastr.error(data.message);
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {
  error(arg0: string) {
    throw new Error('Method not implemented.');
  }
  constructor(
    private _ToastrService: ToastrService
  ) { }

  showSuccess(text: string, title = 'Success'): void {
    this._ToastrService.info(text, title);

  }
  showError(text: string, title = 'Error'): void {
    this._ToastrService.error(text, title);
  }

  showWarning(text: string, title = 'Warning'): void {
    this._ToastrService.warning(text, title);
  }

  showInfo(text: string, title = 'Info'): void {
    this._ToastrService.info(text, title);
  }
}

import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'app/services/main.service';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css'],
})
export class EditRoleComponent implements OnInit {
  id: any;
  getPrivilage: any;
  createRoleForm: FormGroup;
  singleRole: any;
  getRole: any;
  rols = new FormControl();
  rolsList: string[] = ['User Management Read', 'User Management Delete'];
  preSlectedValues: any[] = [];

  constructor(
    private mainService: MainService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditRoleComponent>,
    private toastr: ToastrService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.singleRole.privileges) {
      this.preSlectedValues = this.singleRole.privileges.map((item) => item.id);
    }
    this.mainService.getprivileges().then((data) => {
      this.getPrivilage = data.data;
    });

    this.createRoleForm = this.fb.group({
      rolename: new FormControl(this.singleRole.roleName),
      privilegeIds: new FormControl(this.preSlectedValues),
    });
  }
  onroleChange(ob) {
    var selectedRole = ob.value;
    this.getRole = selectedRole;
  }

  editRole() {
    const body = {
      id: this.singleRole.id,
      privilegeIds: this.getRole,
    };
    this.mainService.editRole(body).then((data) => {
      if (data && data.isSuccess) {
        this.toastr.success(data.message);
        this.dialogRef.close({ event: 'close' });
      } else {
        this.toastr.error(data.message);
      }
    });
  }
}

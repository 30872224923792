import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CreatePrivComponent } from '../../app/prialage-management/create-priv/create-priv.component';
import { EditPrivComponent } from '../../app/prialage-management/edit-priv/edit-priv.component';
import { DeletePrivComponent } from '../../app/prialage-management/delete-priv/delete-priv.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MainService } from 'app/services/main.service';
import { NotifierService } from 'app/services/helper/notifier.service';

export interface UserData {
  id: string;
  name: string;
  act: string;
}

@Component({
  selector: 'app-prialage-management',
  templateUrl: './prialage-management.component.html',
  styleUrls: ['./prialage-management.component.scss'],
})
export class PrialageManagementComponent implements OnInit, AfterViewInit {
  charAt: any;
  id: any;
  roledata: any;
  displayedColumns: string[] = ['id', 'Privileges', 'act'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  modalRef?: BsModalRef;
  priviligesId: any;

  constructor(
    public dialog: MatDialog,
    private _NotifierService: NotifierService,
    private modalService: BsModalService,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.getprivilegesmngt();
  }

  ngAfterViewInit() {
    this.getprivilegesmngt();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getprivilegesmngt() {
    this.mainService.getprivileges().then((res: any) => {
      if (res && res.isSuccess && res.data && res.data.length > 0) {
        this.dataSource = res.data;
        this.dataSource = new MatTableDataSource(this.roledata);
        this.dataSource.paginator = this.paginator;
        this.roledata = res.data;
      }
    });
  }

  getSearchPrivilage(searchText: any) {
    this.mainService.getSearchPrivileges(searchText).then((res: any) => {
      if (res && res.isSuccess && res.data && res.data.length > 0) {
        this.dataSource = res.data;
      } else {
        this.dataSource = null;
      }
    });
  }

  deletePrivilage() {
    this.mainService.deletePrivilage(this.id).then((data) => {});
  }
  search(key: any) {
    const searchText = key.target.value;
    if (searchText.length) {
      this.getSearchPrivilage(searchText);
    } else {
      this.getprivilegesmngt();
    }
  }

  openDialogPriv() {
    const dialogRef = this.dialog.open(CreatePrivComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.event == 'close') {
        this.ngOnInit();
      }
    });
  }
  openDialogPrivEdit() {
    this.dialog.open(EditPrivComponent);
  }

  deleteModal(privilege: any, template: any) {
    this.priviligesId = privilege.id;
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg',
    });
  }

  deleteModalConfirmation(action: string) {
    if (action === 'yes') {
      this.deleteRole();
    } else if (action === 'no') {
      this.modalRef?.hide();
    }
  }

  deleteRole() {
    let id = this.priviligesId;
    this.mainService.deletePrivilage(id).then(
      (data) => {
        if (data.isSuccess === true) {
          this._NotifierService.showSuccess(data.message);
          this.modalRef?.hide();
        } else {
          this._NotifierService.showError(data.message);
          this.modalRef?.hide();
        }
      },
      (error) => {
        if (error.status == 400) {
          this._NotifierService.showError(error.error.message);
        }
      }
    );
  }

  openDialogPrivDelete(data: any) {
    const dialogRef = this.dialog.open(DeletePrivComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.event == 'close') {
        this.ngOnInit();
      }
    });
    this.roledata = data;
    this.dialog.open(DeletePrivComponent);
  }
}

export interface TableData {
  Privileges: string;
}

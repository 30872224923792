import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { BaseResponse } from '../models/base-response.modal'


@Injectable({
  providedIn: 'root'
})
export class MainService {
  localid: any;
  singledata: any;
  apiRoot: string;

  constructor(private _ApiService: ApiService) { }

  // forgotPassword(email:any){
  //   return this._ApiService.getRequest<any>(`user/changePassword?email=${email}`,{} );
  // }
  passwordChange(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<any>('user/changePassword', body);
  }

  getroll(): Promise<BaseResponse> {
    return this._ApiService.getRequest<any>('admin/getAllRoles');
  }
  getSearchRoll(searchText: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<any>(`admin/getAllRoles?searchedKeyword=${searchText}`);
  }

  createRole(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<any>('admin/createRoles', body);
  }

  getprivileges(): Promise<BaseResponse> {
    return this._ApiService.getRequest<any>('admin/getAllPrivileges');
  }
  getSearchPrivileges(searchText: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<any>(`admin/getAllPrivileges?searchedName=${searchText}`);
  }
  createPrivilege(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<any>('admin/createPrivileges', body);
  }

  editRole(body: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<any>(`admin/editRole`, body);
  }

  deleteRole(roleId: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<any>(`admin/deleteRole?id=${roleId}`);

  }

  deletePrivilage(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<any>(`admin/deletePrivilege?id=${id}`);
  }


  getAdmin(): Promise<BaseResponse> {
    return this._ApiService.getRequest('admin/getAllAdmins');
  }

  getAdminUserList(pageNo: number, pageSize: number, search?: string) {
    let params: string;

    if (search) {
      params = `page=${pageNo}&limit=${pageSize}&searchedKeyword=${search}`;
    } else {
      params = `page=${pageNo}&limit=${pageSize}`;
    }
    return this._ApiService.getRequest<BaseResponse>(`admin/getAllAdmins?${params}`);
  }

  getSearchAdmin(searchText: any): Promise<BaseResponse> {
    return this._ApiService.getRequest(`admin/getAllAdmins?searchedKeyword=${searchText}`);
  }

  createAdmin(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<any>(`admin/createAdmin`, body);
  }

  updateAdmin(id: any, fullName: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<any>(`admin/editAdmin?id=${id}&fullName=${fullName}`, fullName);

  }

  deleteAdmin(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<any>(`admin/deleteAdmin?id=${id}`);

  }
  changeAdminStatus(id: any, status: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<any>(`admin/changeAdminStatus?id=${id}&status=${status}`, {});

  }


  getAllAudience(status: any,pageNo: number, pageSize: number, search?: string, sortby?: string): Promise<BaseResponse> {
    let params: string;
    if (search) {
      params = `status=${status}&page=${pageNo}&limit=${pageSize}&searchedKeyword=${search}`;
    } else if (sortby) {
      params = `status=${status}&page=${pageNo}&limit=${pageSize}&sortBy=${sortby}`;
    } else {
      params = `status=${status}&page=${pageNo}&limit=${pageSize}`;
    }

    return this._ApiService.getRequest<BaseResponse>(`admin/getAllAudience?${params}`);
  }

  getAllArtistRequests(status: any, pageNo: number, pageSize: number, search?: string, sortby?: string): Promise<BaseResponse> {
    let params: string;
    if (search) {
      params = `status=${status}&page=${pageNo}&limit=${pageSize}&searchedKeyword=${search}`;
    } else if (sortby) {
      params = `status=${status}&page=${pageNo}&limit=${pageSize}&sortBy=${sortby}`;
    } else {
      params = `status=${status}&page=${pageNo}&limit=${pageSize}`;
    }

    return this._ApiService.getRequest<BaseResponse>(`admin/getAllArtists?${params}`);
  }

  getStaffPicked(pageNo: number, pageSize: number, search?: string, sortby?: string) {
    let params: string;

    if (search) {
      params = `page=${pageNo}&limit=${pageSize}&searchedKeyword=${search}`;
    } else if (sortby) {
      params = `page=${pageNo}&limit=${pageSize}&sortBy=${sortby}`;
    } else {
      params = `page=${pageNo}&limit=${pageSize}`;
    }
    return this._ApiService.getRequest<BaseResponse>(`admin/getStaffPickedArtists?${params}`);
  }

  updateArtistRequest(body: any) {
    return this._ApiService.postRequest<BaseResponse>(`admin/acceptRejectArtistRequest`, body);
  }

  updateStaffPick(user: any) {
    return this._ApiService.postRequest<BaseResponse>(`admin/staffPick?userId=${user.userId}&staffPick=${user.staffPicked}`, {});
  }

  getGeneralProfileById(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getGeneralProfileDetails/${userId}`)
  }

  getessentailProfileById(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getEssentialDetails/${userId}`)
  }

  getAudienceProfileDetails(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getAudienceProfileDetails/${id}`);
  }

  getMediaDetails(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getMediaDetails/${userId}`)
  }

  getBackgroundDetails(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getBackgroundDetails/${userId}`)
  }
  getProductionDetails(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getProductionCapabilities/${userId}`)
  }

  getProfilepic(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getProfilepic/${userId}`)
  }

  getAllAdminEventList(pageNo: number, pageSize: number, search?: string, sortby?: string, dateTime?:any) {
    let params: string;

    if (search) {
      params = `pageNo=${pageNo}&limit=${pageSize}&keyword=${search}`
    } else if (sortby) {
      params = `pageNo=${pageNo}&limit=${pageSize}&sortBy=${sortby}`;
    }else if(dateTime) {
      params = `pageNo=${pageNo}&limit=${pageSize}&startDateTime=${dateTime.startDate}&endDateTime=${dateTime.endDate}`;
   } else {
      params = `pageNo=${pageNo}&limit=${pageSize}`;
    }

    return this._ApiService.getRequest<BaseResponse>(`event/getAllAdminEventList?${params}`)
  }

  getEventDetailById(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getEventDetailById?id=${id}`);
  }

  getAllProposalList(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getAllProposalList/${id}`);
  }

  getEditProposalDraft(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getProposalDetails/${id}`);
  }

  recoverDeletedAccount(id: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(`admin/recoverDeletedAccount?userId=${id}`, {})
  }

  permanentDeleteUserAcc(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<any>(`admin/permanentDeleteUserAcc?userId=${id}`);
  }
  suspendUserAccount(id: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<any>(`admin/suspendAccount?userId=${id}`,{});
  }
  banUserAccount(id: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<any>(`admin/banUserAccount?userId=${id}`,{});
  }
  getAdminDashboardData(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`admin/getAdminDashboardData`);
  }
  approveOrRejectBan(id: any, action: any) {
    return this._ApiService.postRequest<BaseResponse>(`admin/approveRejectPendingBanUser?userId=${id}&action=${action}`, {});
  }
  recoverSuspendedAccount(id: any) {
    return this._ApiService.postRequest<BaseResponse>(`admin/recoverSuspendedAccount?userId=${id}`, {});
  }
  stripeTransfer(eventId: any, proposalId: any, userId: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<any>(`event/stripeTransfer?eventId=${eventId}&proposalId=${proposalId}&userId=${userId}`,{});
  }
  getStreamingUrl(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getStreamingUrl?eventId=${id}`)
  }
  startLiveStream(id: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/startLiveStreaming?eventId=${id}`, {})
  }
  searchGenres(name: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/searchGenres?keyword=${name}`
    );
  }
  getTimeZone(countryName?: any, search?: string): Promise<BaseResponse> {
    let params: string;
    if (search && countryName) {
      params = `countryName=${countryName}&searchKeyword=${search}`;
    } else if (search) {
      params = `searchKeyword=${search}`;
    } else {
      params = `countryName=${countryName}`;
    }
    return this._ApiService.getRequest<BaseResponse>(
      `user/getTimeZones?${params}`
    );
  }
  getAgeList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getAgeList`);
  }
  fileUpload(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/fileUpload`, body);
  }
  updateProfilePic(profilePicUrl: string): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(
      `user/updateProfilePic?profilePicUrl=${profilePicUrl}`,
      {}
    );
  }
  getDeviceList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getDeviceList`);
  }
  saveOrUpdateAudienceProfileDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>( `user/updateAudienceProfileDetailsByAdmin`, body );
  }
  emailChangeByAdmin(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>( `user/emailChangeByAdmin`, body );
  }
  getBandSize(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getBandSize`);
  }
  saveOrUpdateBackgroundDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/updateBackgroundDetailsByAdmin`,body);
  }
  getEducationList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getEducationList`);
  }
  getOnsiteList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getOnsiteList`);
  }
  getVideoSetupList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getVideoSetupList`);
  }
  getVideoQualityList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getVideoQualityList`);
  }
  getAudioSetupList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getAudioSetupList`);
  }
  getPerformanceEnvironmentList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getPerformanceEnvironmentList`);
  }
  essentailDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/updateEssentialDetailsByAdmin`,body);
  }
  deleteImage(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(`user/deleteImage?imageDetailId=${id}`);
  }
  deleteVideo(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(`user/deleteVideo?videoDetailId=${id}`);
  }
  searchInstuments(name: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/searchInstruments?keyword=${name}`);
  }
  uploadCerificateDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/uploadCertificateDetails`,body);
  }
  addVideoDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>('user/addVideoDetails',body);
  }
  addImageDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>('user/addImageDetails',body);
  }
  saveOrUpdateMediaDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>('user/updateMediaDetailsByAdmin',body);
  }
  saveorUpdateArtistGeneralDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>('user/updateGeneralProfileDetailsByAdmin',body);
  }
  saveOrUpdateProductionCapabilities(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/updateProductionCapabilitiesByAdmin`,body);
  }
  fileUploadImage(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/fileUpload`, body);
  }
  removeCertificateDetails(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(`user/removeCertificateDetails?id=${id}`);
  }
  editCertificateDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(`user/editCertificateDetails`,body);
  }
  saveProPlaylist(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/saveProPlaylist`, body)
  }
  getProPlaylistDetails(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getProPlaylistDetails?identifierId=${id}`)
  }

  deleteProPlaylist(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(`event/deleteProPlaylist?proPlaylistIds=${id}`);
  }
  updatePROPlaylistOrder(body: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(`event/updatePROPlaylistOrder`, body);
  }
  getUserAccPendingDetails(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getUserAccPendingDetails`)
  }
  getProEventList(keyword?: any): Promise<BaseResponse> {
    let url = keyword? 'event/getEventsOfProPlaylist?keyword='+keyword : 'event/getEventsOfProPlaylist'
    return this._ApiService.getRequest<BaseResponse>(`${url}`)
  }
  downloadProList(data: any): Promise<BaseResponse> {
    return this._ApiService.downloadSheet<BaseResponse>(`event/generateProPlaylist`,data?data:{})
  }
}



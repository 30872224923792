import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from '../../app/services/helper/session.service';
import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {


    constructor(
        private _Injector: Injector,
        private _SessionService: SessionService,
        private _Router: Router
      ){}


      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url !== 'https://backend.virtuosica.com/api/v1/user/refreshToken'){
          const _SessionService = this._Injector.get(SessionService);
              const token = _SessionService.getSession('token');
              if (token != null) {
                const reqCloned = req.clone({
                  setHeaders: {
                    Authorization: `${token}`,
                    AcceptLanguage: 'en'
                  }
                });
               return next.handle(reqCloned);
              } else {
                return next.handle(req);
              }
            } else if(req.url === 'https://backend.virtuosica.com/api/v1/user/refreshToken') {
              const _SessionService = this._Injector.get(SessionService);
              const refreshToken = _SessionService.getSession('refreshToken');
              if(refreshToken!=null){
              const reqCloned = req.clone({
                  setHeaders: {
                    refreshToken: `${refreshToken}`,
                    AcceptLanguage: 'en'
                  }
                });
              return next.handle(reqCloned).pipe(
                catchError((err: HttpErrorResponse) => {
                  if(err && err.status === 401) {
                    this._SessionService.removeSessions();
                    this._Router.navigate(['/auth/login']);
                  }
                  return throwError(err);
                })
              );
              }else{
                  return next.handle(req);
              }
          }else {
            return next.handle(req);
          }
        }

}

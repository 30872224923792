import { Injectable, OnInit } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { data } from 'jquery';
import { SessionService } from 'app/services/helper/session.service';
import { AuthService } from 'app/services/auth/auth.service';


@Injectable()
export class LoginComponentResolver implements Resolve<any>{
    constructor(
        private _AuthService: AuthService,
        private _SessionService: SessionService,
        private _Router: Router
    ) { }
  
    resolve(): Promise<any> {
       return this._AuthService.getCurrentIpInfo().then(data => data);
    }
}
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { AuthService } from 'app/services/auth/auth.service';
import { NotifierService } from 'app/services/helper/notifier.service';
import { SessionService } from 'app/services/helper/session.service';
@Component({
  selector: 'app-verfiy-otp',
  templateUrl: './verfiy-otp.component.html',
  styleUrls: ['./verfiy-otp.component.css'],
})
export class VerfiyOtpComponent implements OnInit {
  submitted = false;
  verfiyOtpForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    public dialog: MatDialog,
    private _SessionService: SessionService,
    private NotifierService: NotifierService,
    public dialogRef: MatDialogRef<VerfiyOtpComponent>
  ) {
    dialogRef.disableClose = true;

    this.verfiyOtpForm = fb.group({
      otp: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      ],
    });
  }

  ngOnInit(): void {
    // this.auth.localEmail;
  }

  openDialog() {
    const dialogRef = this.dialog.open(ResetPasswordComponent);

    dialogRef.afterClosed().subscribe((result) => {});
  }

  verfiyOtp() {
    if (this.verfiyOtpForm.invalid) {
      return false;
    }
    let email = this._SessionService.getSession('email');

    const body = {
      email: email,
      otp: this.verfiyOtpForm.value.otp,
    };
    this.auth.otpVerfiy(body).then((data) => {
      if (data.isSuccess === true) {
        this._SessionService.setSessions({ token: data.data });
        this.dialogRef.close();
        this.openDialog();
      }
    });
  }
}



export class AuthModal {
  accountLocked: boolean;
  confirmPassword: string;
  email: string;
 id: number;
  lastName: string;
  middleName: string;
  password: string;
  roleId: number;
  roleName: string;
  status: string;
  userName: string;
  dateOfBirth: any;
  constructor() {
    this.accountLocked = false;
    this.confirmPassword = '';
    this.email = '';
    this.id = 0;
    this.lastName = '';
    this.middleName = '';
    this.password = '';
    this.roleId = 0;
    this.roleName = '';
    this.status = 'ACTIVE';
    this.userName = '';
  }
}


export class LoginModal {
  browser: string;
  country: string;
  device: string;
  email: string;
  loginIp: string;
  loginLocation: string;
  password: string;
  constructor(
  ) {
    this.browser = '';
    this.country = '';
    this.device = window && window.navigator && window.navigator.platform ? window.navigator.platform : 'web_device';
    this.email = '';
    this.loginIp = '';
    this.loginLocation = '';
    this.password = '';
  }
}

export class AuthModalwithSocial {
  login: LoginModal;
  user: AuthModal
  constructor() {
    this.login = new LoginModal();
    this.user = new AuthModal();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VerfiyOtpComponent } from '../verfiy-otp/verfiy-otp.component';
import { MainService } from 'app/services/main.service';
import { AuthService } from 'app/services/auth/auth.service';
import { NotifierService } from 'app/services/helper/notifier.service';
import { SessionService } from 'app/services/helper/session.service';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
})
export class ForgetPasswordComponent implements OnInit {
  submitted = false;
  forgetPasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private mainService: MainService,
    private _SessionService: SessionService,
    private auth: AuthService,
    public dialog: MatDialog,
    private NotifierService: NotifierService,
    public dialogRef: MatDialogRef<ForgetPasswordComponent>
  ) {
    dialogRef.disableClose = true;

    this.forgetPasswordForm = fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
          ),
        ]),
      ],
    });
  }

  ngOnInit(): void {}

  openDialog() {
    this.dialog.open(VerfiyOtpComponent);
  }

  passwordForgot() {
    if (this.forgetPasswordForm.invalid) {
      this.NotifierService.showError('Error', 'Form not filled properly');
      return false;
    }

    this._SessionService.setSessions({
      email: this.forgetPasswordForm.value.email,
    });

    this.auth
      .forgotPassword(this.forgetPasswordForm.value.email)
      .then((data) => {
        if (data && data.isSuccess) {
          this.dialogRef.close();
          this.openDialog();
          this.NotifierService.showSuccess(data.message);
        } else {
          this.NotifierService.showError(data.message);
        }
      });
  }
}

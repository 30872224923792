import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Location, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from 'app/services/helper/session.service';
import { RefreshService } from 'app/services/refresh.service';
import { MainService } from 'app/services/main.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  interval: any;

  constructor(
    public location: Location,
    public _refreshService: RefreshService,
    private _SessionService: SessionService,
    private router: Router,
   
  ) {}

  ngOnInit() {
    let refreshTokenLog: any[] = [];
    this.interval = setInterval(() => {
      this.refreshToken();
      refreshTokenLog.push({
        message: 'refresh token api called',
        dateTime: new Date(),
      });
      localStorage.setItem('refreshTokenLog', JSON.stringify(refreshTokenLog));
    }, 900000);

    const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

    if (isWindows) {
      document
        .getElementsByTagName('body')[0]
        .classList.add('perfect-scrollbar-on');
    } else {
      document
        .getElementsByTagName('body')[0]
        .classList.remove('perfect-scrollbar-off');
    }
    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>(
      document.querySelector('.sidebar .sidebar-wrapper')
    );

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else window.scrollTo(0, 0);
      }
    });
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
      });
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
    }
  }
  ngAfterViewInit() {
    this.runOnRouteChange();
  }
  isMap(path) {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1);
    if (path == titlee) {
      return false;
    } else {
      return true;
    }
  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    }
  }
  refreshToken() {
    this._refreshService.refreshToken().then((data) => {
      if (data && data.isSuccess) {
        this.router.navigate(['/dashboard']);
        this._SessionService.removeSession('token');
        this._SessionService.setSessions({ token: data.data });
      } else {
      }
    });
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }
}

import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MustMatch } from './password validation/confirm-validation';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'app/services/main.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  hide = true;
  
  constructor(
    private fb: FormBuilder,
    private mainService: MainService,
    private toastr: ToastrService,
    private router: Router
  ) {}
  ngOnInit() {
    this.changePasswordForm = this.fb.group(
      {
        oldPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{8,}$/
            ),
          ],
        ],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{8,}$/
            ),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
  }

  get oldPassword() {
    return this.changePasswordForm.get('oldPassword');
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }

  changePassword() {
    this.mainService
      .passwordChange(this.changePasswordForm.value)
      .then((data: any) => {
        if (data.isSuccess === true) {
          this.toastr.success('Successful', 'Password changed successfully');
          this.router.navigateByUrl('/dashboard');
        } else {
          this.toastr.error('Error', 'Something went wrong');
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from './password validation/confirm-validation';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/services/auth/auth.service';
import { SessionService } from 'app/services/helper/session.service';
import { NotifierService } from 'app/services/helper/notifier.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  submitted = false;
  resetPasswordForm: FormGroup;
  resetPassworData: any;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private _NotifierService: NotifierService,
    private _SessionService: SessionService,
    private router: Router,
    public dialogRef: MatDialogRef<ResetPasswordComponent>
  ) {
    dialogRef.disableClose = true;
    this.resetPasswordForm = fb.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{8,}$/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
  }

  ngOnInit(): void {
  
  }

  resetPaswword() {
    if (this.resetPasswordForm.invalid) {
      return false;
    }
    let email = this._SessionService.getSession('email');
    let token = this._SessionService.getSession('token');
    const body = {
      confirmPassword: this.resetPasswordForm.value.confirmPassword,
      email: email,
      newPassword: this.resetPasswordForm.value.newPassword,
      token: token,
    };
    this.auth.resetPassword(body).then((data) => {
      if (data.isSuccess === true) {
        this._NotifierService.showSuccess(data.message);
        this._SessionService.removeSession('email');
        this._SessionService.removeSession('token');
        this.dialogRef.close();
      } else {
       }
    });
  }
}

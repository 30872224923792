import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'app/services/main.service';

@Component({
  selector: 'app-create-priv',
  templateUrl: './create-priv.component.html',
  styleUrls: ['./create-priv.component.css'],
})
export class CreatePrivComponent implements OnInit {
  createPrivilegeForm: FormGroup;
  constructor(
    private mainService: MainService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<CreatePrivComponent>
  ) {}

  ngOnInit(): void {
    this.createPrivilegeForm = this.fb.group({
      name: new FormControl(''),
    });
  }
  selectedValue: string;

  createPrivilege() {
    const body = {
      name: this.createPrivilegeForm.value.name,
    };
    this.mainService.createPrivilege(body).then((data) => {
      if (data && data.isSuccess) {
        this.toastr.success(data.message);
        this.dialogRef.close({ event: 'close' });
      } else {
        this.toastr.error(data.message);
      }
    });
  }
}

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { NotifierService } from '../helper/notifier.service';
import { SessionService } from '../helper/session.service';



@Injectable({
  providedIn: 'root'
})

export class ApiService {

  apiRoot: string;

  constructor(
    private _HttpClient: HttpClient,
    private router: Router, private NotifierService: NotifierService,
    private _SessionService: SessionService
  ) {
    this.apiRoot = environment.baseUrl;
  }



  postRequest<T>(url: string, body: any, options?: IRequestOptions): Promise<T> {
    const { apiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .post(`${apiRoot}${url}`, body, { headers })
      .toPromise()
      .then(res => res as T)
      .catch(err => this.handleCatchError<any>(err));
  }

  getRequest<T>(url: string, options?: IRequestOptions): Promise<T> {
    const { apiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .get(`${apiRoot}${url}`, { headers })
      .toPromise()
      .then(res => res as T)
      .catch(err => this.handleCatchError<any>(err));
  }
  downloadSheet<T>(url: string, body: any, options?: IRequestOptions): Promise<T> {
    const { apiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .post(`${apiRoot}${url}`,body, { headers, responseType: 'blob' })
      .toPromise()
      .then((res) => res as any)
      .catch((err) => this.handleCatchError<any>(err));
  }

  putRequest<T>(url: string, body: any, options?: IRequestOptions): Promise<T> {
    const { apiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .put(`${apiRoot}${url}`, body, { headers })
      .toPromise()
      .then(res => res as T)
      .catch(err => this.handleCatchError<any>(err));
  }

  deleteRequest<T>(url: string, options?: IRequestOptions): Promise<T> {
    const { apiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .delete(`${apiRoot}${url}`, { headers })
      .toPromise()
      .then(res => res as T)
      .catch(err => this.handleCatchError<any>(err));
  }

  // downloadPdf(url: string, body: any, options?: IRequestOptions): Promise<any> {
  //   const { apiRoot, headers } = this.getApiRootAndHeaders(options);
  //   return this._HttpClient
  //     .post(`${apiRoot}${url}`, body, { headers, responseType: 'blob' })
  //     .toPromise()
  //     .then(res => res as any)
  //     .catch(err => this.handleCatchError<any>(err));
  // }





  // handle errors
  private handleCatchError<T>(err: HttpErrorResponse) {
    if ((err.status === 404) || (err.status === 0 && err.url === null)) {
      // tslint:disable-next-line: no-console
      console.log('HttpError', 'Server not available');
      return err.error;
    }
    if ((err.status === 401)) {
      // tslint:disable-next-line: no-console
      // this._refresh.refreshToken().then(res => {
      //   if(res && res.data) {
      //     this._SessionService.removeSession('token');
      //     this._SessionService.setSessions({ token: res.data });
      //   }
      // });
      console.log('HttpError', 'Unauthorized');
      this.NotifierService.showError('Session Expired');
      this._SessionService.removeSessions();
      this.router.navigate(['/login']);
      return err.error;
    }
    if (err.status !== 0 && err.status !== 200 && err.status !== 401 && err.status !== 404) {
      // tslint:disable-next-line: no-console
      console.log('HttpError', 'Server Error');
      return err.error;
    }
    return err.error;
  }

  private getApiRootAndHeaders(options?: IRequestOptions): IRequestOptions {
    return {
      apiRoot: (options && options.apiRoot) ? options.apiRoot : this.apiRoot,
      headers: (options && options.headers) ? options.headers : new HttpHeaders()
    };
  }

}

interface IRequestOptions {
  headers?: HttpHeaders;
  apiRoot?: string;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service'
import { BaseResponse } from '../models/baseResponse'


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
     private _ApiService: ApiService)
     {}

  login(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`admin/adminLogin`, body);
  }

  forgotPassword(email:any): Promise<BaseResponse>{
    return this._ApiService.getRequest<BaseResponse>(`user/forgotPassword?email=${email}`)
  }

  otpVerfiy(body:any): Promise<BaseResponse>{
    return this._ApiService.postRequest<BaseResponse>(`user/verifyOtp`, body);
   }

  resetPassword(body:any): Promise<BaseResponse>{
    return this._ApiService.postRequest<BaseResponse>(`user/resetPassword`, body);
  }


  logOut(device:any): Promise<BaseResponse>{
    return this._ApiService.postRequest<BaseResponse>(`user/logout?device=${device}`, {});
  }


  getCurrentIpInfo(): Promise<any> {
    const token = 'c0da6d29d587e2'
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json');
    header = header.set("Authorization", "Bearer " + token)
    return this._ApiService.getRequest<any>(`/json`, { apiRoot: 'https://ipinfo.io', headers: header })
  }


  detectBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return `Opera`;
    }
    else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    }
    else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    }
    else if ((navigator.userAgent.indexOf("MSIE") != -1)) {
      return 'IE';
    }
    else {

      return 'unknown';
    }
  }




}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';





@NgModule({
    imports: [ RouterModule, CommonModule, MatIconModule,MatFormFieldModule ],
    declarations: [ NavbarComponent ],
    exports: [ NavbarComponent  ]
})

export class NavbarModule {}
